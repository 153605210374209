<template>
    <div>
        <v-overlay :value="loading" color="background" opacity="1" z-index="200">
            <Loader/>
        </v-overlay>
        <v-row class="maxwidth">
            <v-col
                cols="6"
                v-for="(repo, i) in repos"
                :key="i"
                class="repo"
            >
                <Repo :repository="repo"/>
            </v-col>
        </v-row>
    </div>

</template>

<script>
import {getPinnedRepos} from "@/utils/githubApi";
import Repo from "@/components/Repo";
import Loader from "@/components/Loader";

export default {
    name: "Repos",
    components: {Repo, Loader},
    data: () => ({
        loading: true,
        repos: {},
    }),
    async mounted() {
        this.repos = await getPinnedRepos()
        this.loading = false
        console.log(this.repos)
    }
}
</script>

<style scoped>
.maxwidth {
    max-width: 40rem;
}
</style>