<template>
    <div>
        <h1 class="text-h3 text-sm-h2">
            <span class="accent--text">@</span>metafates
        </h1>
    </div>
</template>

<script>
export default {
    name: "Title"
}
</script>
