<template>
    <div class="d-flex justify-space-around align-center">
        <v-btn
            v-for="(link, i) in links"
            :key="i"
            icon
            :href="link.url"
            target="_blank"
            color="accent"
            large
        >
            <v-icon>{{ link.icon }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "Social",
    data: () => ({
        links: [
            {
                icon: "mdi-github",
                name: "Github",
                url: "https://github.com/metafates"
            }
        ]
    })
}
</script>

<style scoped>

</style>