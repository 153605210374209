import gql from "graphql-tag";

export default gql`
{
    user(login: "metafates") {
        pinnedItems(first: 6) {
            totalCount
            edges {
                node {
                    ... on Repository {
                        name
                        url
                        stargazerCount
                        description
                    }
                }
            }
        }
    }
}
`