<template>
    <v-app>
        <v-btn icon x-large @click="changeTheme" class="theme-switch" style="z-index: 100">
            <v-icon
                color="accent"
            >{{ themeSwitchIcon }}
            </v-icon>
        </v-btn>
        <v-main class="background py-10">
            <v-container fill-height class="justify-center">
                <div class="d-flex flex-column justify-center align-center">
                    <Social/>
                    <Title class="mb-16"/>
                    <Repos/>
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Title from "@/components/Title";
import Repos from "@/components/Repos";
import Social from "@/components/Social";

export default {
    name: 'App',
    components: {Social, Repos, Title},
    methods: {
        changeTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        }
    },
    computed: {
        themeSwitchIcon() {
            return this.$vuetify.theme.dark ? 'mdi-lightbulb-off-outline' : 'mdi-lightbulb-on'
        }
    },
    mounted() {
        // Adapt for system theme
        if (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            this.$vuetify.theme.dark = true
        }
        // Watch for system theme change
        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (e) => {
                this.$vuetify.theme.dark = e.matches
            })
    }
};
</script>

<style scoped>
.theme-switch {
    position: absolute;
    left: 20px;
    top: 20px;
}
</style>
