<template>
    <v-card :href="repository.url" target="_blank" height="100%" color="backgroundsecondary">
        <div class="accent" style="height: .5rem"></div>
        <v-card-title class="font-weight-light">

            <v-icon color="accent" left small>mdi-pin</v-icon>
            {{ repository.name }}

            <v-spacer></v-spacer>

            <div class="d-flex align-center" v-if="$vuetify.breakpoint.smAndUp">
                <v-icon x-small left color="yellow darken-3">mdi-star</v-icon>
                <span class="text-subtitle-2">{{repository.stargazerCount}}</span>
            </div>
        </v-card-title>
        <v-card-subtitle>{{ descriptionWithoutEmoji }}</v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    name: "Repo",
    props: {
        repository: {
            type: Object,
            required: true
        }
    },
    computed: {
        descriptionWithoutEmoji() {
            return this.$props.repository.description.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')
        }
    },
}
</script>

<style scoped>
</style>