import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
} from "@apollo/client/core"
import {setContext} from "@apollo/client/link/context";
import query from "./query"

const httpLink = createHttpLink({
    uri: 'https://api.github.com/graphql',
});

const authLink = setContext((_, {headers}) => {
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${process.env.VUE_APP_GITHUB_ACCESS_TOKEN}`
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export async function getPinnedRepos() {
    const {data} = await client.query(({query}))
    return data.user.pinnedItems.edges.map(e => e.node)
}

